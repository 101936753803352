import React from "react";

import { Container, Grid, Typography } from "@material-ui/core";
import Logo from "@landingpage/app/src/images/logo.svg";
import Layout from "../components/Layout";
import SEO from "../components/SEO";


const NotFoundPage = () => (
  <Layout>
    <SEO title="404: not found" />
    <Container>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item style={{ textAlign: "center" }}>
          <Logo style={{ marginTop: "1.5rem" }} width="85" height="80" />
          <Typography style={{ marginTop: "1.5rem" }} variant="h1">
            Page not found
          </Typography>
          <Typography>
            The link you clicked may be broken
            <br />
            or the page may have been removed.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  </Layout>
);

export default NotFoundPage;
